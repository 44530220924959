<template>
  <div class="grid grid-cols-[130px_1fr] items-center gap-x-4 py-3">
    <h5 v-if="!hideTitle" class="leading-none">
      {{ $t('parcel.orderStatus') }}
    </h5>
    <div class="flex h-7 items-center" :class="{ 'w-max': hideTitle }">
      <div class="inline-block size-5 rounded-full" :class="statusColor" />
      <p class="mb-0 ml-1 inline-block text-sm">
        {{ statusLabel }}
      </p>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { ParcelCurrentStatus } from '~/enums/ParcelCurrentStatus'
const { parcelStatuses } = useParcelsData()
const { t } = useI18n()

type Props = {
  status: ParcelCurrentStatus
  hideTitle?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  hideTitle: false
})

const statusLabel = computed((): string => {
  if (parcelStatuses[props.status as keyof typeof parcelStatuses]?.label) {
    return parcelStatuses[props.status as keyof typeof parcelStatuses]?.label
  }
  if (props.status) {
    return props.status
  }

  return t('parcel.noShipmentMethod')
})

const statusColor = computed((): string => {
  if (parcelStatuses[props.status as keyof typeof parcelStatuses]?.color) {
    return `bg-${
      parcelStatuses[props.status as keyof typeof parcelStatuses]?.color
    }-500`
  }
  return 'bg-signal-blue-500'
})
</script>
