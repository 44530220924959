<template>
  <div class="mt-6 flex">
    <PackageVariantClosedIcon
      color="7b105a"
      :size="32"
      class="mr-2 mt-0.5 inline-block"
    />
    <p class="mb-4 grow text-2xl text-aubergine-900">
      <span class="font-baloo2">{{ $t('parcel.combine') }}:</span>
      {{ parcel.id }}
    </p>
  </div>
  <div class="mb-3 grid grid-cols-[200px_auto_130px] gap-5">
    <h5>{{ $t('parcel.deliveries') }}</h5>
    <h5>{{ $t('parcel.status') }}</h5>
    <h5>{{ $t('parcel.date') }}</h5>
  </div>
  <div
    v-for="otherParcel in otherUserParcels"
    :key="otherParcel.id"
    class="grid cursor-pointer grid-cols-[200px_auto_130px] items-center gap-5"
    @click="mergeParcelTo = otherParcel.id"
  >
    <div class="flex h-7 items-center">
      <input
        :id="`parcel-id-merge-${otherParcel.id}`"
        name="parcel-id-merge"
        type="radio"
        :checked="mergeParcelTo === otherParcel.id"
        class="size-4 border-gray-300 text-aubergine-500 focus:ring-aubergine-500"
        @input="mergeParcelTo = otherParcel.id"
      />
      <label
        :for="`parcel-id-merge-${otherParcel.id}`"
        class="mb-0 ml-2 block cursor-pointer text-sm font-medium text-gray-700"
      >
        {{ otherParcel.id }}
      </label>
    </div>
    <ParcelShipmentStatus
      :status="otherParcel.CurrentStatus"
      :hide-title="true"
    />
    <WpBadge>
      {{ formatTimestamp(otherParcel?.Created) }}
    </WpBadge>
  </div>

  <div class="mt-6 grid grid-cols-2 gap-4">
    <WpButton variant="light-outline" @click="emit('close')">
      {{ $t('general.cancel') }}
    </WpButton>
    <WpButton
      type="submit"
      variant="signal-blue"
      :disabled="!contentParcelChecked"
      :loading="loading"
      @click="mergeParcels()"
    >
      {{ $t('parcel.combine') }}
    </WpButton>
  </div>
</template>

<script lang="ts" setup>
import type { Parcel, ParcelItem } from '~/models'
import {
  query,
  getDocs,
  where,
  orderBy,
  arrayUnion,
  runTransaction,
  type DocumentData,
  type Transaction
} from 'firebase/firestore'
const { collection } = useFirebase()
const { parcelStatusesOpen } = useParcelsData()
const { formatTimestamp, toast } = useHelpers()
const { db, doc } = useFirebase()
const { t } = useI18n()

const props = defineProps<{
  parcel: Parcel
  parcelItems: ParcelItem[]
  checkedParcelItems: ParcelItem[]
}>()

const emit = defineEmits(['close'])

const otherUserParcels = ref<Parcel[]>([])
onMounted(async () => {
  await getUserOpenParcels()
})

async function getUserOpenParcels() {
  const userOpenParcelQuery = query(
    collection('Parcels'),
    where('UserId', '==', props.parcel.UserId),
    where('CurrentStatus', 'in', parcelStatusesOpen),
    orderBy('DeliveryDate', 'asc')
  )

  const { docs } = await getDocs(userOpenParcelQuery)
  const queryParcelsOpen = docs.map((doc: DocumentData) => {
    return {
      ...doc.data(),
      id: doc.id,
      ref: doc.ref
    }
  })

  otherUserParcels.value = queryParcelsOpen.filter(
    (parcel: Parcel) => parcel.id !== props.parcel.id
  )
}

const mergeParcelTo = ref('')
const contentParcelChecked = computed(
  (): boolean => !!(props.checkedParcelItems.length && mergeParcelTo.value)
)

const loading = ref(false)
async function mergeParcels(): Promise<void> {
  loading.value = true
  if (!mergeParcelTo.value) return

  const deleteOrUpdateParcel = checkToDeleteOrUpdate()
  try {
    await runTransaction(db, async (transaction: Transaction) => {
      transaction.update(doc('Parcels', mergeParcelTo.value), {
        ParcelItems: arrayUnion(...props.checkedParcelItems)
      })

      if (deleteOrUpdateParcel.deleteParcel) {
        transaction.delete(doc('Parcels', props.parcel.id))
      } else {
        transaction.update(doc('Parcels', props.parcel.id), {
          ParcelItems: deleteOrUpdateParcel?.parcelItems
        })
      }
    })

    handleSuccess()
  } catch (e) {
    console.debug('Transaction Parcels failed: ', e)
  }
  loading.value = false
}

type CheckToDeleteOrUpdate = {
  deleteParcel: boolean
  parcelItems?: ParcelItem[]
}

function checkToDeleteOrUpdate(): CheckToDeleteOrUpdate {
  const parcelItemsLeft = props.parcelItems.filter(
    (item) =>
      !props.checkedParcelItems.some(
        (checkedItem) =>
          checkedItem.Created.nanoseconds === item.Created.nanoseconds &&
          checkedItem.DisplayName === item.DisplayName
      )
  )

  if (parcelItemsLeft.length === 0) {
    return {
      deleteParcel: true
    }
  }

  return {
    deleteParcel: false,
    parcelItems: parcelItemsLeft
  }
}

function handleSuccess() {
  toast.success(t('parcel.merged'))
  loading.value = false
  emit('close', true)
}
</script>
