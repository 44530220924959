import type {
  Medicine,
  MedicineArticle,
  MedicineProduct,
  ParcelItem
} from '~/models'
import {
  getDoc,
  getDocs,
  where,
  query,
  DocumentReference
} from 'firebase/firestore'

export default function useMedicines() {
  const { doc, collection, getDocument } = useFirebase()

  async function getMedicineForParcelItem(parcelItem: ParcelItem) {
    if (!parcelItem.MedicineProductRef) return

    const medicineProduct = await getDocument<MedicineProduct>(
      parcelItem.MedicineProductRef.path
    )

    if (!medicineProduct?.MedicineRef) return

    return await getDocument<Medicine>(medicineProduct.MedicineRef.path)
  }

  async function getMedicineArticle(articleNumber: number) {
    const articleQuery = query(
      collection('MedicineArticles'),
      where('ArticleNumber', '==', articleNumber)
    )

    const { docs } = await getDocs(articleQuery)
    if (docs.length > 0) {
      return docs[0].data() as MedicineArticle
    }
    return null
  }

  async function getMedicineArticleById(id: string) {
    return await getDocument<MedicineArticle>(`MedicineArticles/${id}`)
  }

  async function getMedicineHPK(medicineArticle: MedicineArticle) {
    const medicineProduct = await getDoc(
      doc<MedicineProduct>(medicineArticle.MedicineProductRef.path)
    )

    // Get HPK value from Article --> Product --> Medicine
    if (!medicineProduct.exists()) {
      return
    }

    const medicineProductPath = medicineProduct.data()?.MedicineRef?.path
    if (!medicineProductPath) {
      return
    }

    const medicine = await getDoc(doc<Medicine>(medicineProductPath))
    if (!medicine.exists() && !medicine.data()?.HPK) {
      return
    }

    return medicine.data()?.HPK
  }

  async function getUnitAbbreviation(
    MedicineProductRef?: DocumentReference
  ): Promise<string | undefined> {
    if (!MedicineProductRef) {
      return
    }

    const medicineProduct = await getDoc(
      doc<MedicineProduct>('MedicineProducts', MedicineProductRef.id)
    )

    if (!medicineProduct.exists()) {
      return
    }

    const medicineRef = medicineProduct.data().MedicineRef
    if (!medicineRef) {
      return
    }

    const medicine = await getDoc(doc<Medicine>('Medicines', medicineRef.id))

    if (!medicine.exists()) {
      return
    }

    return medicine.get('UnitAbbreviation')
  }

  return {
    getMedicineArticle,
    getMedicineArticleById,
    getMedicineForParcelItem,
    getMedicineHPK,
    getUnitAbbreviation
  }
}
